import React from 'react';

const Contact = () => {
  return (
    <div className='w-full px-4 py-16 bg-black text-white text-center'>
      <h1 className='text-4xl font-bold mb-4'>Contact Us</h1>
      <p className='text-2xl mb-8'>
        Let's collaborate to <span className='text-[#00d8ff]'>Innovate</span>, 
        <span className='text-[#00d8ff]'> Empower</span>, and 
        <span className='text-[#00d8ff]'> Evolve</span> together!
      </p>
      <div className='flex flex-col items-center'>
        <p className='text-xl font-bold'>Email:</p>
        <a href='mailto:krish.gupta@zohtek.com' className='text-lg mb-8'>
          krish.gupta@zohtek.com
        </a>
        <p className='text-xl font-bold'>Phone number:</p>
        <a href='tel:+61483815547' className='text-lg'>
          +61 483815547
        </a>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react'
import About from './components/About';
import Team from './components/Team';
import Hero from './components/Hero';
import Contact from './components/Contact';

function App() {
  return (
    <div>
        <Hero />
        <About />
        <Team />
        <Contact />
    </div>
  );
}

export default App;

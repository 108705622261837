// AboutCard.js
import React from 'react';

const AboutCard = (props) => {
  return (
    <div className='flex flex-col border text-center rounded-2xl py-12 px-8 items-center'>
      <div className='inline-flex p-2 rounded-full justify-center items-center'>
        <img src={props.icon} alt="icon" className="w-[200px] h-[200px]" />
      </div>
      <h3 className='text-xl font-bold py-2'>{props.heading}</h3> {/* Reduced padding here */}
      <p>
        {props.text}
      </p>
    </div>
  );
};

export default AboutCard;

import React from 'react'

const TeamCard = (props) => {
  return (
    <div className='flex flex-col text-centre rounded-2xl py-12 px-8'>
    <div>
        <div className='inline-flex p-2 rounded-full justify-center items-center'>
            <img src={props.icon} alt="icon" className="w-[170px] h-[170px]" />
        </div>
      <h3 className='text-xl font-bold'>{props.heading}</h3>
      <p>
        {props.text}
      </p>
    </div>
  </div>
  )
}

export default TeamCard
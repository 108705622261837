import React from 'react';
import heroBg from '../images/Bg.png';
import Zogo from '../images/Zogo.png';

const Hero = () => {
  return (
    <div className='w-full h-[90vh] flex flex-col items-start justify-center relative'>
      <img
        className='object-cover h-full w-full absolute -z-10'
        src={heroBg}
      />
      <div className='w-full text-white text-left px-4'>
        <img src={Zogo} alt="Logo" className="w-[500px] h-auto mt-2 mx-auto" />
        <div className='mt-8'>
          <h1 className='text-6xl'>Innovate</h1>
          <h1 className='text-6xl py-2'>Empower</h1>
          <h1 className='text-6xl py-2'>Evolve</h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;

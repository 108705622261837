import React from 'react';
import Tech from '../images/Tech.png';
import NDIS from '../images/NDIS.png';
import AboutCard from './AboutCard';

const About = () => {
  return (
    <div className='w-full bg-black text-[#D3D3D3] text-center'>
      <div className='max-w-[1240px] mx-auto px-4 py-16'>
        <div>
          <h1 className='py-4'>Services</h1>
          <p className='py-4 text-xl'>
            Our firm provides a diverse suite of professional services, spanning from NDIS Health Behavioral Practitioner services and comprehensive therapeutic offerings, to a variety of Technological Services.
          </p>

          {/* Card Container */}
          <div className='flex justify-center gap-4'>
            {/* Card */}
            <AboutCard 
              icon={Tech} 
              heading='Technology Services' 
              text={
                <>
                  <h3 style={{ marginTop: '20px', textAlign: 'left', fontWeight: 'bold' }}>Cloud Engineering</h3>
                  <p style={{ textAlign: 'left' }}>
                  Our services harmonize DevOps and Cloud Engineering to optimize your tech operations. With DevOps, we enhance collaboration and hasten deployment through continuous integration and delivery (CI/CD). We deftly manage Azure, AWS, and GCP in Cloud Engineering, leveraging Infrastructure as Code (IaC) with Terraform and Codefresh for efficient cloud provisioning. We aim to expedite your time to market, stimulate innovation, and foster scalable, secure cloud solutions that grow with your business needs.</p>
                  <h3 style={{ marginTop: '20px', textAlign: 'left', fontWeight: 'bold' }}>Modern Workspaces</h3>
                  <p style={{ textAlign: 'left' }}>
                  Our Modern Workspace Services offer advanced digital solutions to optimize productivity and collaboration. We utilize technologies like Windows 365, Azure Virtual Desktop, and Citrix Virtual Desktops to create personalized, secure user experiences across various devices and locations. Whether cloud-based platforms or virtual environments, we facilitate a seamless shift to flexible, efficient, and specific modern workspaces.</p>
                  <h3 style={{ marginTop: '20px', textAlign: 'left', fontWeight: 'bold' }}>AI & Machine Learning Solutions</h3>
                  <p style={{ textAlign: 'left' }}>
                  Our AI and Machine Learning competencies span the entire development lifecycle: designing and implementing solutions with Python, managing Azure Machine Learning workspaces, and deploying models following best-practice MLOps. We also generate Conversational AI, Knowledge Mining, NLP, and Computer Vision solutions to push the boundary of what's possible.</p>
                  <h3 style={{ marginTop: '20px', textAlign: 'left', fontWeight: 'bold' }}>Web Development & Business Intelligence</h3>
                  <p style={{ textAlign: 'left' }}>
                  Our skilled team crafts web applications utilizing modern tools such as ReactJS, TypeScript, and Azure, supplemented by BI tools including Yellowfin BI, Power BI, and Tableau for intelligent business insights. We also integrate websites with various APIs to enhance functionality and user experience.</p>
                  <h3 style={{ marginTop: '20px', textAlign: 'left', fontWeight: 'bold' }}>Functional Skills:</h3>
                  <p style={{ textAlign: 'left' }}>
                  We possess robust functional skills in Enterprise Architecture, Software Engineering, Global Project Delivery, and Consulting Services. Agile and DevOps methodologies guide our work, fostering collaboration between development and operations teams to deliver successful projects seamlessly.
                  With a proven record across a spectrum of industries—BFSI, Healthcare, Education, Government, Telco, Pharma, Manufacturing, ITeS, and Retail—we're equipped to address critical business needs with bespoke technology services. Our partnerships with clients across Australia, ASEAN, India, and the United States testify to our technical leadership and ability to navigate complex challenges.
                  </p>
                </>
              }
            />

            <AboutCard 
              icon={NDIS} 
              heading='Allied Health Services' 
              text={
                <>
                  <h3 style={{ marginTop: '20px', textAlign: 'left', fontWeight: 'bold' }}>NDIS Positive Behaviour Support:</h3>
                  <p style={{ textAlign: 'left' }}>
                  Our experienced team of professionals is dedicated to enhancing lives through Positive Behaviour Support (PBS). It is committed to making a positive difference in the lives of the individuals we serve. We design individualised behaviour treatment plans by fostering meaningful human interactions and using evidence-based strategies tailored to each person's needs and strengths. Collaborating with families, caregivers, and support networks, we encourage skill acquisition and promote social development. Our inclusive and supportive environment ensures everyone receives the highest quality care and support. Join us on this journey to empower individuals and foster positive change through Positive Behaviour Support.</p>
                  <h3 style={{ marginTop: '20px', textAlign: 'left', fontWeight: 'bold' }}>Teenage Counselling:</h3>
                  <p style={{ textAlign: 'left' }}>
                    Our expert PreTeen and Teen Counselling services are tailored to the unique needs of adolescents navigating the challenges of growing up. Our compassionate counsellors provide a safe space for young individuals to express themselves, explore emotions, and develop essential coping skills. Using therapeutic approaches like Cognitive Behavioral Therapy (CBT), Person-Centered Therapy, Art Therapy, and Mindfulness techniques, we help teens build resilience, self-confidence, and a positive outlook on life. Our goal is to empower teens to navigate their journey to adulthood with strength and confidence, promoting emotional well-being and fostering healthy relationships. Let us be your partner in supporting your preteens and teens as they thrive and achieve their fullest potential.
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
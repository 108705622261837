import React from 'react';
import TeamCard from './TeamCard';
import Krish from '../images/1.png';
import Girish from '../images/2.png';
import Avan from '../images/3.png';
import Khiana from '../images/4.png';

const Team = () => {
  return (
    <div className='w-full bg-black text-[#D3D3D3] text-center'>
      <div className='max-w-[1240px] mx-auto px-4 py-16'>
        <div>
          <h1 className='py-4'>Meet the team</h1>

          {/* Card Container */}
          <div className='flex justify-center gap-8'>
            {/* Group 1 - Applied Health Services */}
            <div className='border border-white p-4 rounded-md'>
              <h2 className='text-[#D3D3D3] text-3xl font-bold underline'>Allied Health Services</h2>
              <div className='flex gap-4'>
                <TeamCard
                  icon={Avan}
                  heading='Avan'
                  text='Behaviour Practitioner'
                />
                <TeamCard
                  icon={Khiana}
                  heading='Khiana'
                  text='Support Assistant'
                />
              </div>
            </div>

            {/* Group 2 - Technology Services */}
            <div className='border border-white p-4 rounded-md'>
              <h2 className='text-[#D3D3D3] text-3xl font-bold underline'>Technology Services</h2>
              <div className='flex gap-2'>
                <TeamCard
                  icon={Girish}
                  heading='Girish'
                  text='Technology Consultant'
                />
                <TeamCard
                  icon={Krish}
                  heading='Krish'
                  text='Data Engineer'
                />  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
